import request from '@/utils/request'

/**
  * 登录
  * @param data { userId }
  * @returns 
  */
export function userLogin(data) {
  return request({
    method: 'post',
    url: `/cls/passport-personal/app/loginAndRegister/outChannel/userLogin`,
    data,
  })
}

/**
  * 查询合同协议
  * @param loanNo
  * @returns 
  */
export function previewContract(loanNo) {
  return request({
    method: 'get',
    url: `/cls/core/app/lcAppl/outChannel/previewContract/${loanNo}`,
  })
}

/**
  * 同意协议
  * @param loanNo
  * @returns 
  */
export function agreeLoanContract(loanNo) {
  return request({
    method: 'post',
    url: `/cls/core/app/doLoan/outChannel/agreeLoanContract/${loanNo}`,
    data: {},
  })
}
