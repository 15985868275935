<template>
  <div class="container">
    <van-tabs v-model="active" :swipe-threshold="3">
      <van-tab :title="item.contractName" :disabled="tabDisabled && index !== 0" v-for="(item, index) in protocolList" :key="index">
        <iframe :src="item.viewUrl" width="100%" height="100%" frameborder="0"></iframe>
      </van-tab>
    </van-tabs>
    <div class="btn-box" v-show="protocolList.length > 0">
      <van-button round type="primary" block :disabled="(active === 0 && secondCount >= 0) || loading" @click="agreeOnClick">
        {{ btnText }}
      </van-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Tab, Tabs, Button } from 'vant'
import { userLogin, previewContract, agreeLoanContract } from '@/apis/protocol'
import { getToken, setToken } from '../../utils/auth'

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Button)

const AGREE_TEXT = '我已同意并阅读本协议';
const DEFAULT_READ_TIME = 5; // 默认第一个合同的倒计时时间（秒）
let timer = null;

export default {
  data() {
    return {
      active: 0,
      btnText: AGREE_TEXT,
      secondCount: 0,
      tabDisabled: true,
      protocolList: [],
      loading: false,
      callbackUrl: '',
      symbol: '?', // ? | &
    }
  },
  created() {},
  async mounted() {
    const { userId, loanNo, channelCode, code, callBackUrl } = this.$route.query;
    if (!userId || !loanNo || !channelCode || !code || !callBackUrl) {
      this.$common.toast('缺少必要参数')
      this.$router.push('/')
      return
    }
    this.callbackUrl = decodeURIComponent(callBackUrl);
    if (this.callbackUrl.includes('?')) {
      this.symbol = '&'
    }
    const loginRes = await userLogin({userId, channelCode, code})
    if (!loginRes.success) {
      this.$common.toast(loginRes.msg || '登录失败')
      window.location.href = `${this.callbackUrl}${this.symbol}userId=${userId}&loanNo=${loanNo}&channelCode=${channelCode}&signStatus=0`
      return
    }
    setToken(loginRes.data.token)

    const previewRes = await previewContract(loanNo)

    if (!previewRes.success || previewRes.data.length === 0) {
      this.$common.toast(previewRes.msg || '获取合同列表失败')
      window.location.href = `${this.callbackUrl}${this.symbol}userId=${userId}&loanNo=${loanNo}&channelCode=${channelCode}&signStatus=0`
      return
    }

    this.protocolList = previewRes.data.sort((a, b) => a.displaySort - b.displaySort)
    this.secondCount = this.protocolList[0].readSeconds || DEFAULT_READ_TIME;
    document.title = this.protocolList[0].displayName

    this.agreeCountDown()
  },
  watch: {
    active(value) {
      if (this.protocolList[value]) {
        document.title = this.protocolList[value].displayName
      }
    },
  },
  computed: {},
  methods: {
    /**
     * 阅读合同倒计时
     */
    agreeCountDown() {
      timer = setInterval(() => {
        this.btnText = this.secondCount === 0 ? AGREE_TEXT : `${AGREE_TEXT}(${this.secondCount}秒)`;
        if(this.secondCount < 0){
          this.btnText = AGREE_TEXT;
          this.tabDisabled = false;
          clearInterval(timer);
        } else {
          this.secondCount--;
        }
      }, 1000);
    },

    /**
     * 同意按钮点击
     */
    async agreeOnClick() {
      if (this.secondCount > 0) {
        return;
      }
      if (this.active < this.protocolList.length - 1) {
        this.active++;
      } else {
        this.loading = true;
        const agreeRes = await agreeLoanContract(this.$route.query.loanNo)
        this.loading = false;
        if (agreeRes.success) {
          this.$common.toast('协议签署完成')
        } else {
          this.$common.toast(agreeRes.msg || '同意协议失败')
        }
        const signStatus = agreeRes.success ? 1 : 0;
        const { userId, loanNo, channelCode } = this.$route.query;
        // 跳转到第三方页面
        window.location.href = `${this.callbackUrl}${this.symbol}userId=${userId}&loanNo=${loanNo}&channelCode=${channelCode}&signStatus=${signStatus}`
      }
    }
  },
}
</script>
<style lang="less" scoped>
/deep/ .van-tabs{
  height: 90%;
}
/deep/ .van-tabs__content{
  height: 100%;
  background-color: #fff;
}
/deep/ .van-tab__pane{
  height: 100%;
}
/deep/ .van-tabs__line{
  background-color: #1989fa;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container{
  height: 89vh;
  background-color: #fff;
  position: relative;
  .btn-box{
    position: fixed;
    bottom: 40px;
    width: 100%;
    text-align: center;
    /deep/ .van-button{
      width: 80%;
      margin: 0 auto;
      background-color: #1989fa !important;
      border: 1px solid #1989fa !important;
    }
  }
}
</style>
