/* eslint-disable */
import axios from 'axios'
import { Toast, Dialog } from 'vant'
import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import * as common from '@/utils/common'
// import Cookies from 'js-cookie'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers['Accept-language'] = Cookies.get('language')
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 30000,
})
// request拦截器
service.interceptors.request.use(
  (config) => {
    let stsyemName = 'default'
    const { isAndroid, isIOS } = common.default
    if (isAndroid()) {
      stsyemName = 'Android'
    } else if (isIOS()) {
      stsyemName = 'IOS'
    }
    config.headers.stsyem_name = stsyemName
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = `${config.url}?`
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName]
        const part = `${encodeURIComponent(propName)}=`
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              const params = `${propName}[${key}]`
              const subPart = `${encodeURIComponent(params)}=`
              url += `${subPart + encodeURIComponent(value[key])}&`
            }
          } else {
            url += `${part + encodeURIComponent(value)}&`
          }
        }
      }
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 获取流水号 token
    res.headers['verify-code'] && sessionStorage.setItem('verify-code', res.headers['verify-code'])

    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode.default
    if (code === 2401 || code === 2405) {
      removeToken()
      Dialog.confirm({
        title: '系统提示',
        message: '登录状态已过期，您可以继续留在该页面，或者重新登录',
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        store.dispatch('LogOut').then(() => {
          location.href = `/phoneLogin?appId=${store.state.common.appId}`
        })
      })
    } else if (code === 700) {
      Toast.fail(msg)
      return Promise.reject(new Error(msg))
    } else {
      return res.data
    }
  },
  (error) => {
    let { message } = error
    if (message == 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = `系统接口${message.substr(message.length - 3)}异常`
    }
    Toast({
      message,
      type: 'fail',
      duration: 5 * 1000,
    })
    return Promise.reject(error)
  }
)

export default service
